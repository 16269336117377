<template>
  <div>
    <ul class="team-list">
      <li class="flex-box flex-col-start" v-for="(item, index) in list" :key="index">
        <img class="img-avatar mr30" :src="item.logo_image_url" alt="" />
        <div class="flex-1 fs20 col-9">
          <div class="flex-box mb25">
            <div class="name flex-1">{{ item.name }}</div>
            <el-rate v-model="item.winning" disabled></el-rate>
          </div>
          <div class="flex-box mb10">
            <div class="flex-1">代拍价格</div>
            <div>累计委托 {{ item.num }}人</div>
          </div>
          <div class="mb20">{{ item.min_price }}¥～{{ item.max_price }}¥</div>
          <div class="flex-box">
            <div class="m-ellipsis-l2 flex-1">团队介绍：{{ item.info }}</div>
            <router-link :to="{ path: '/teamDetail', query: { id: item.id } }" class="nav pointer">查看详情</router-link>
          </div>
        </div>
      </li>
    </ul>
    <div @click="getlist()" v-if="currentPage < totalPage" class="g-btn-more">查看更多</div>
  </div>
</template>
<script>
export default {
  name: 'TeamList',
  props: {},
  data() {
    return {
      currentPage: 0,
      currentSize: 10,
      totalPage: 1,
      list: []
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    getlist() {
      this.currentPage++
      if (this.currentPage > this.totalPage) {
        return false
      }
      var params = { page: this.currentPage }
      this.$http
        .get('/api/team/list', {
          params
        })
        .then(res => {
          this.totalPage = res.data.data.last_page
          if (res.data.data.data.length === 0) {
            this.nodata = 0
          } else {
            this.nodata = 1
          }
          if (this.currentPage === 1) {
            this.list = res.data.data.data
          } else {
            this.list = this.list.concat(res.data.data.data)
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.team-list {
  li {
    padding: 30px 0;
    .img-avatar {
      display: block;
      width: 102px;
      height: 102px;
      border-radius: 50%;
    }
    .name {
      font-size: 30px;
      color: #333333;
      line-height: 35px;
    }
    .nav {
      margin: 0 0 0 160px;
      width: 160px;
      height: 60px;
      background: #f6e65f;
      border-radius: 10px;
      font-size: 20px;
      color: #333333;
      text-align: center;
      line-height: 60px;
    }
  }
  li + li {
    border-top: 1px solid #efefef;
  }
}
</style>
