import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "about" */ '../views/Case.vue')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team/Team.vue')
  },
  {
    path: '/teamDetail',
    name: 'TeamDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team/TeamDetail.vue')
  },
  {
    path: '/placeOrder',
    name: 'PlaceOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team/PlaceOrder.vue')
  },
  {
    path: '/placeOrderSecond',
    name: 'PlaceOrderSecond',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team/PlaceOrderSecond.vue')
  },
  {
    path: '/signContract',
    name: 'SignContract',
    component: () => import(/* webpackChunkName: "about" */ '../views/Team/SignContract.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mine/Mine.vue'),
    children: [
      {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/Profile.vue')
      },
      {
        path: '/myOrder',
        name: 'MyOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/MyOrder.vue')
      },
      {
        path: '/myCoupon',
        name: 'MyCoupon',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/MyCoupon.vue')
      },
      {
        path: '/balance',
        name: 'Balance',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/Balance.vue')
      },
      {
        path: '/operationTeach',
        name: 'OperationTeach',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/OperationTeach.vue')
      },
      {
        path: '/kefu',
        name: 'KeFu',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/KeFu.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/About.vue')
      },
      {
        path: '/orderDetail',
        name: 'OrderDetail',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/OrderDetail.vue')
      },
      {
        path: '/contract',
        name: 'Contract',
        component: () => import(/* webpackChunkName: "about" */ '../views/Mine/Contract.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

const router = new VueRouter({
  routes
})

export default router
