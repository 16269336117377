<template>
  <div id="app">
    <router-view />
    <Footer />
    <div class="g-btn-kefu">
      <a href="https://www.v5kf.com/public/chat/chat?sid=164032&entry=5&ref=link&human=0" target="_blank">
          <img src="@/assets/icon/icon_service1.png"/>
      </a>
    </div>
    <!-- <router-link to="/"></router-link> -->
  </div>
</template>
<script>
import Footer from '@/components/footer/Footer.vue'
export default {
  components: {
    Footer
  }
}
</script>
<style></style>
